<template>
  <!-- 考试签到照片组件 -->
  <div class="studentInfoCertificationRecord_6">
    <div class="ovy-a">
      <div class="topCz">
        <el-button type="primary" class="bgc-bv" size="mini" @click="clearExamData" round>清除学习记录</el-button>
      </div>
      <div class="shells">
        <div class="shell" v-for="(item, index) in photoList" :key="item.faceId">
          <el-image :src="item.approveFaceUrl" :preview-src-list="previewList" style="width:100%;height:100%;" />
          <div class="shell_time">{{ item.createTime }}</div>
          <el-checkbox v-model="item.select" @change="handleCheckedCitiesChange(index)"></el-checkbox>
        </div>
        <Empty v-if="photoList.length < 1" slot="empty" />
      </div>
    </div>
    <!-- 分页器 -->
    <div class="pagination">
      <PageNum style="margin-top:20px;" :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange" />
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
export default {
  name: "studentInfoCertificationRecord_6",
  components: {
    PageNum,
    Empty
  },
  props: {
    // 当前选项卡激活项
    tabs: {
      type: String,
      default: "",
    },
    // 班级id
    projectId: {
      type: String,
      default: "",
    },
    // 学员id
    userId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 列表数据
      photoList: [],
      // 人脸id
      selectPhoto: [],
      // 预览照片列表
      previewList: [],
      // 分页器数据
      pagination: {
        current: 1, //当前页
        size: 100, //每页大小
        total: 0 //总数
      }
    }
  },
  methods: {
    // 重新设置
    resetData() {
      this.previewList = [];
    },
    // 获取考试签到照片数据
    getExamSignPhotoList() {
      const { pagination, userId, projectId } = this;
      // 必须重新设置数据
      this.resetData();
      this.$post("/biz/project/projectUser/everyDay/login/pageList", {
        userId, projectId, // 学员id和班级id
        pageNum: pagination.current,
        pageSize: pagination.size
      }).then(res => {
        const arr = res?.data?.list || [];
        arr.forEach(item => {
          item.select = false;
          this.previewList.push(item.approveFaceUrl);
        });
        this.photoList = arr;
        pagination.total = res?.data?.total || 0;
      }).catch(e => {
        console.error("获取考试签到照片数据出错", e);
      });
    },
    // 勾选要删除的人脸
    handleCheckedCitiesChange(index) {
      const { photoList, selectPhoto } = this;
      for (let i = 0; i < photoList.length; i++) {
        if (i != index) {
          photoList[i].select = false;
        }
      }
      if (photoList[index].select) {
        if(selectPhoto.length > 0){
          selectPhoto.splice(0, 1);
        }
        selectPhoto.push(photoList[index].faceId);
      }

      // if (photoList[index].select) {
      //   // 如果是选中 将faceId推到selectPhone数组中
      //   selectPhoto.push(photoList[index].faceId);
      // } else {
      //   // 拿到选中图片的faceId下标移除
      //   const indexItem = selectPhoto.indexOf(photoList[index].faceId);
      //   selectPhoto.splice(indexItem, 1);
      // }
    },
    // 点击删除
    clearExamData() {
      console.log(this.selectPhoto)
      this.$post("/biz/project/projectUser/everyDay/login/delete", {
        faceId: this.selectPhoto[0], // 人脸id
      }).then(res => {
        this.getExamSignPhotoList();
        this.$message({
          type: "success",
          message: "操作成功"
        });
      }).catch(e => {
        console.error("删除失败", e);
      });
    },
    // 当前页改变
    currentChange(current) {
      const { pagination } = this;
      pagination.current = current;
      this.getExamSignPhotoList();
    },
    // 每页条数改变
    sizeChange(size) {
      const { pagination } = this;
      pagination.size = size;
      this.getExamSignPhotoList();
    }
  },
  watch: {
    // 监听 - userId
    userId: {
      handler: function () {
        this.getExamSignPhotoList();
      },
      immediate: true,
    },
  },
}
</script>

<style lang="less" scoped>
.studentInfoCertificationRecord_6 {
  height: 100%;

  .topCz {
    overflow: hidden;
    margin-bottom: 20px;
    .el-button {
      float: right;
      margin-left: 10px;
    }
  }

  .shells {
    height: 80%;
    overflow-y: auto;

    .shell {
      width: 120px;
      height: 120px;
      float: left;
      margin-right: 15px;
      margin-bottom: 15px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }
      .shell_time {
        font-size: 12px;
      }

      .el-checkbox {
        position: absolute;
        right: 0;
        top: -1px;
      }
    }
  }

  .pagination {
    clear: both;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>