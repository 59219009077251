<template>
  <div class="studentInfoCertificationRecord_2">
    <div class="ovy-a">
      <div class="topCz">
        <el-checkbox v-model="allSelect" @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <el-button
          type="primary"
          class="bgc-bv"
          size="small"
          round
          @click="noQualified"
          >不合格</el-button
        >
        <el-button
          type="primary"
          class="bgc-bv"
          size="small"
          round
          @click="setAsHighQualityPhoto"
          >设为优质照片</el-button
        >
      </div>
      <div class="shells">
        <div class="shell" v-for="(item, index) in listData" :key="index">
          <!-- <img :src="item.approveFaceUrl" alt="" /> -->
          <el-image
            style="width: 100%; height: 100%"
            :src="item.approveFaceUrl"
            :preview-src-list="urlData"
          />
          <el-checkbox
            v-model="item.select"
            @change="handleCheckedCitiesChange"
          ></el-checkbox>
        </div>
        <Empty v-if="!listData.length" slot="empty" />
      </div>
    </div>
    <div class="pageNum">
      <el-pagination
        :total="fenYe.total"
        :page-size="fenYe.size"
        :current-page="fenYe.currentPage"
        background
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="total, sizes,prev, pager, next,jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import { resetKeepAlive } from "@/utils/common";

export default {
  name: "studentInfoCertificationRecord_2",
  components: {
    Empty,
  },
  props: {
    tabs: {
      type: String,
      default: "",
    },
    projectId: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 全选
      allSelect: false,
      // 列表数据
      listData: [],
      urlData: [],
      // 分页
      fenYe: {
        currentPage: 1, // 当前页数
        size: 100,
        total: null, // 共多少页
      },
    };
  },
  created() {},
  mounted() {},
  watch: {
    // 监听 - userId
    userId: {
      handler: function (val) {
        this.fenYe.currentPage = 1;
        this.getListData();
      },
      immediate: true,
    },
  },
  methods: {
    // 点击全选
    handleCheckAllChange(val) {
      if (val) {
        for (let index = 0; index < this.listData.length; index++) {
          this.listData[index].select = true;
        }
      } else {
        for (let index = 0; index < this.listData.length; index++) {
          this.listData[index].select = false;
        }
      }
    },
    // 点击单个
    handleCheckedCitiesChange(value) {
      let num = 0;
      for (let index = 0; index < this.listData.length; index++) {
        if (this.listData[index].select) {
          num++;
          if (num >= this.listData.length) {
            this.allSelect = true;
          } else {
            this.allSelect = false;
          }
        }
      }
    },
    // 不合格
    noQualified() {
      let arr = [];
      for (let index = 0; index < this.listData.length; index++) {
        if (this.listData[index].select) {
          arr.push(this.listData[index].faceId);
        }
      }
      this.$post("/biz/project/user/faceIden/handle", {
        handleState: 10,
        faceIdList: arr,
      })
        .then((res) => {
          this.getListData();
        })
        .catch(() => {
          return;
        });
    },
    // 设为优质照片
    setAsHighQualityPhoto(){
        let arr = [];
        for (let index = 0; index < this.listData.length; index++) {
            if (this.listData[index].select) {
                arr.push(this.listData[index].faceId);
            }
        }
        this.$post("biz/project/user/faceIden/excellent", {
            handleSource: "20",
            faceIdList: arr,
        }).then(() => {
            this.$message.success("操作成功");
            this.getListData();
        }).catch(() => {
            return;
        });
    },
    // 每页多少条
    handleSizeChange(val) {
      this.fenYe.size = val;
      this.fenYe.currentPage = 1;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 当前页数
    handleCurrentChange(val) {
      this.fenYe.currentPage = val;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 获取列表数据
    getListData(page = 1, pagesize = this.fenYe.size) {
      const params = {
        handleState: 20,
        projectId: this.projectId,
        userId: this.userId,
        pageNum: page,
        pageSize: pagesize,
      };
      return this.$post("/biz/project/user/faceIdens", params)
        .then((res) => {
          for (let index = 0; index < res.data.list.length; index++) {
            res.data.list[index].select = false;
            this.urlData.push(res.data.list[index].approveFaceUrl);
          }
          this.listData = res.data.list || [];
          this.fenYe.total = res.data.total;
          this.allSelect = false;
        })
        .catch(() => {
          return;
        });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.studentInfoCertificationRecord_2 {
  height: 100%;
  .topCz {
    overflow: hidden;
    margin-bottom: 20px;
    .el-button {
      float: right;
      margin-left: 10px;
    }
  }
  .shells {
    height: 80%;
    overflow-y: auto;
    .shell {
      width: 100px;
      height: 100px;
      float: left;
      margin-right: 15px;
      margin-bottom: 15px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .el-checkbox {
        position: absolute;
        right: 0;
        top: -1px;
      }
    }
  }

  .pageNum {
    clear: both;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>