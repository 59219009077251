<template>
  <div class="ovy-a">
      <el-button
          type="primary"
          class="bgc-bv"
          size="small"
          round
           style="float: right; margin-bottom: 10px; margin-right: 30px"
          @click="qualified"
          >合格</el-button
        >
    <el-button
        v-if="studyClearState"
      type="primary"
      class="bgc-bv"
      size="small"
      round
      style="float: right; margin-bottom: 10px; margin-right: 10px"
      @click="clearLearningRecords"
      >清除学习记录</el-button
    >
    <el-table
      ref="multipleTable"
      :data="tableData"
      :height="tableHeight"
      size="small"
      tooltip-effect="dark"
      style="width: 100%"
      :header-cell-style="tableHeader"
      stripe
      @selection-change="handleSelectionChange"
      :key="tableKey"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        label="序号"
        align="center"
        type="index"
        :index="indexMethod"
        width="100px"
      />
      <el-table-column
        label="课程名称"
        align="left"
        show-overflow-tooltip
        prop="courseName"
        width="200"
      />
      <el-table-column
        label="课件名称"
        align="left"
        show-overflow-tooltip
        prop="kpointName"
        width="200"
      />
      <el-table-column label="认证照片" align="left" class-name="facePhotoUrls">
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.faceList"
            :key="index"
            style="width: 80px; height: 70px; padding: 0 5px"
          >
            <el-image
              style="width: 80px; height: 70px; padding: 0 5px"
              :src="item.facePhotoUrl"
              :preview-src-list="[item.facePhotoUrl]"
            >
            </el-image>
          </div>
        </template>
      </el-table-column>
      <Empty slot="empty" />
    </el-table>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/MechanismFaceStudentList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: {
    // 学习记录是否可以清除
    studyClearState:{
        type:Boolean,
        default:false
    },
    tabs: {
      type: String,
      default: "",
    },
    projectId: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 多选 - 全选
      multipleSelection: [],
      tableKey: new Date().getTime(),
    };
  },
  doNotInit: true,
  created() {
  },
  mounted() {},
  watch: {
    // 监听 - userId
    userId: {
      handler: function (val) {
        this.getData(1);
        this.refreshList();
      },
      immediate: true,
    },
  },
  methods: {
    refreshList() {
      this.tableKey = new Date().getTime();
    },
    // 点击多选 - 全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 清除学习记录
    clearLearningRecords() {
      if (this.multipleSelection.length > 0) {
        let arr = [];
        for (let index = 0; index < this.multipleSelection.length; index++) {
          arr.push(this.multipleSelection[index].studyId);
        }
        this.$post("/biz/project/user/unqualified/faceIden/delete/confirm", {
          studyIdList: arr,
        })
            .then((res) => {
              if(res.data){
                this.$confirm(res.data, "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                    .then(() => {
                      this.$post("/biz/project/user/unqualified/faceIden/delete", {
                        studyIdList: arr,
                      })
                          .then((res) => {
                            this.getData(-1);
                          })
                          .catch(() => {
                            return;
                          });
                    })
                    .catch(() => {
                      this.$message({
                        type: "info",
                        message: "已取消清除学习记录",
                      });
                    });
              }else{
                this.$post("/biz/project/user/unqualified/faceIden/delete", {
                  studyIdList: arr,
                })
                    .then((res) => {
                      this.getData(-1);
                    })
                    .catch(() => {
                      return;
                    });
              }

            })
            .catch(() => {
              return;
            });

      } else {
        this.$message({
          type: "warning",
          message: "您还未选择要清除的学习记录",
        });
      }
    },

    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userId: this.userId,
        projectId: this.projectId,
      };
      return this.doFetch({
        url: "/biz/project/user/unqualified/faceIdens",
        params,
        pageNum,
      });
    },
     // 合格
    qualified() {
       if (this.multipleSelection.length > 0) {
        let arr = [];
        for (let index = 0; index < this.multipleSelection.length; index++) {
          for (let n = 0; n < this.multipleSelection[index].faceList.length; n++) {
          arr.push(this.multipleSelection[index].faceList[n].faceId);
        }
        }
        this.$post("/biz/project/user/faceIden/handle", {
        handleState: 20,
        faceIdList: arr,
      })
        .then((res) => {
          this.getData(-1);
        })
        .catch(() => {
          return;
        });
      } else {
        this.$message({
          type: "warning",
          message: "您请至少选择一项",
        });
      }
    
      
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
/deep/.facePhotoUrls {
  .cell {
    display: flex;
  }
}
</style>