<template>
    <!-- 考试签到照片组件 -->
    <div class="studentInfoCertificationRecord_5">
        <div class="ovy-a">
            <div class="topCz">
                <el-checkbox v-model="allSelect" @change="handleCheckAllChange">全选</el-checkbox>
                <el-button type="primary" class="bgc-bv" size="mini" @click="clearExamData" round>清除考试数据</el-button>
            </div>
            <div class="shells">
                <div class="shell" v-for="(item, index) in photoList" :key="item.faceId">
                    <el-image :src="item.approveFaceUrl" :preview-src-list="previewList" style="width:100%;height:100%;"/>
                    <el-checkbox v-model="item.select" @change="handleCheckedCitiesChange(index)"></el-checkbox>
                </div>
                <Empty v-if="photoList.length<1" slot="empty" />
            </div>
        </div>
        <!-- 分页器 -->
        <div class="pagination">
            <PageNum style="margin-top:20px;" :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
        </div>
    </div>
</template>

<script>
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    export default {
        name:"studentInfoCertificationRecord_5",
        components:{
            PageNum,
            Empty
        },
        props: {
            // 当前选项卡激活项
            tabs: {
                type: String,
                default: "",
            },
            // 班级id
            projectId: {
                type: String,
                default: "",
            },
            // 学员id
            userId: {
                type: String,
                default: "",
            },
        },
        data(){
            return{
                // 列表数据
                photoList:[],
                // 选中的图片优质id
                selectPhoto:[],
                // 预览照片列表
                previewList:[],
                // 是否全选
                allSelect: false,
                // 分页器数据
                pagination:{
                    current:1, //当前页
                    size:100, //每页大小
                    total:0 //总数
                }
            }
        },
        methods:{
            // 重新设置
            resetData(){
                this.selectPhoto=[];
                this.previewList=[];
                this.allSelect=false;
            },
            // 获取考试签到照片数据
          getExamSignPhotoList(){
                const {pagination,userId,projectId}=this;
                // 必须重新设置数据
                this.resetData();
                this.$post("/biz/project/user/courseExam/faceIdens",{
                    userId,projectId, // 学员id和班级id
                    pageNum:pagination.current,
                    pageSize:pagination.size
                }).then(res=>{
                    const arr=res?.data?.list ||[];
                    arr.forEach(item=>{
                        item.select=false;
                        this.previewList.push(item.approveFaceUrl);
                    });
                    this.photoList=arr;
                    pagination.total=res?.data?.total||0;
                }).catch(e=>{
                    console.error("获取考试签到照片数据出错",e);
                });
            },
            // 清除考试数据
            clearExamData(){
                const {selectPhoto}=this;
                if(selectPhoto.length == 0){
                  this.$message.error("请至少选择一项再进行清除考试数据操作！");
                  return false;
                }
                this.$post("/biz/project/user/courseExam/unqualified/faceIden/delete",{
                  faceIdList:selectPhoto,
                }).then(()=>{
                    this.$message.success("操作成功");
                    this.getExamSignPhotoList();
                }).catch(e=>{
                    console.error("清除考试数据出错",e);
                });
            },
            // 全选、取消全选
            handleCheckAllChange(val){
                // 无论如何清空selectPhone
                this.selectPhoto=[];
                const {photoList}=this;
                if (val) {
                    photoList.forEach(item=>{
                        item.select=true;
                        this.selectPhoto.push(item.faceId);
                    });
                } else {
                    photoList.forEach(item=>{
                        item.select=false;
                    });
                }
            },
            // 点击单个选中或非选中
            handleCheckedCitiesChange(index) {
                const {photoList,selectPhoto}=this;
                if(photoList[index].select){
                    // 如果是选中 将faceId推到selectPhone数组中
                    selectPhoto.push(photoList[index].faceId);
                }else{
                    // 拿到选中图片的faceId下标移除
                    const indexItem=selectPhoto.indexOf(photoList[index].faceId);
                    selectPhoto.splice(indexItem,1);
                }
                // 计算是否全选状态
                this.allSelect=photoList.length===selectPhoto.length && photoList.length>0?true:false;
            },
            // 当前页改变
            currentChange(current){
                const {pagination}=this;
                pagination.current=current;
                this.getExamSignPhotoList();
            },
            // 每页条数改变
            sizeChange(size){
                const {pagination}=this;
                pagination.size=size;
                this.getExamSignPhotoList();
            }
        },
        watch: {
            // 监听 - userId
            userId: {
                handler:function(){
                    this.getExamSignPhotoList();
                },
                immediate: true,
            },
        },
    }
</script>

<style lang="less" scoped>
    .studentInfoCertificationRecord_5{
        height: 100%;
        .topCz {
            overflow: hidden;
            margin-bottom: 20px;
            .el-button {
                float: right;
                margin-left: 10px;
            }
        }
        .shells {
            height: 80%;
            overflow-y: auto;
            .shell {
                width: 100px;
                height: 100px;
                float: left;
                margin-right: 15px;
                margin-bottom: 15px;
                position: relative;
                img {
                    width: 100%;
                    height: 100%;
                }
                .el-checkbox {
                    position: absolute;
                    right: 0;
                    top: -1px;
                }
            }
        }
        .pagination{
            clear: both;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
</style>