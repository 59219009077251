<template>
    <div class="pageContol listWrap templateList faceStudentstatistics">
        <div class="framePage">
            <div class="framePage-title">
                <!-- 检查认证照片进来的显示这个面包屑 -->
                <span v-if="pageSource==='inspectionOfCertifiedPhotos'">
                    <em>当前位置：</em>
                    <a href="javascript:;">系统监管</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">认证照片检查</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">认证记录</a>
                </span>
                <span v-else>
                    <em>当前位置：</em>
                    <a href="javascript:;">培训管理</a>
                    <i>></i>
                    <a href="javascript:;">班级列表</a>
                    <i>></i>
                    <a href="javascript:;" @click="goHerf" class="cur-a">学员信息</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">认证记录</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="faceCheck">
                    <!-- 学员信息展示区域 -->
                    <div>
                        <span style="margin: 0 20px 0 0">学员姓名：{{ currentInfo.userName || info.userName}}</span>
                        <span style="margin: 0 20px">身份证号：{{ currentInfo.idcard || info.idcard}}</span>
                        <span style="margin: 0 20px 0 0">班级名称：{{ info.projectName }}</span>
                    </div>
                    <!-- 右上角按钮区域 -->
                    <div style="display: flex">
                        <el-button class="bgc-bv" round size="small" @click="pauseResume">{{ currentInfo.isPause?'恢复学习':'暂停学习'}}</el-button>
                        <el-button class="bgc-bv" round size="small" @click="messageReminder">消息提醒</el-button>
                        <el-button class="bgc-bv" round size="small" v-show="currentInfo.lastButtonShow" @click="prevClick(activeName, currentInfo)">上一个</el-button>
                        <el-button class="bgc-bv" round size="small" v-show="currentInfo.nextButtonShow" @click="nextClick(activeName, currentInfo)">下一个</el-button>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="未处理" name="activeName_1">
                            <studentInfoCertificationRecord_1
                                :userId="UserId || info.userId"
                                :projectId="info.projectId"
                                :tabs="activeName"
                                ref="activeName_1"
                            />
                        </el-tab-pane>
                        <el-tab-pane label="已处理" name="activeName_2">
                            <studentInfoCertificationRecord_2
                                :userId="UserId || info.userId"
                                :projectId="info.projectId"
                                :tabs="activeName"
                                ref="activeName_2"
                            />
                        </el-tab-pane>
                        <el-tab-pane label="不合格" name="activeName_3">
                            <studentInfoCertificationRecord_3
                                :userId="UserId || info.userId"
                                :projectId="info.projectId"
                                :studyClearState="info.studyClearState"
                                :tabs="activeName"
                                ref="activeName_3"
                            />
                        </el-tab-pane>
                        <el-tab-pane label="优质照片" name="activeName_4">
                            <studentInfoCertificationRecord_4
                                :userId="UserId || info.userId"
                                :projectId="info.projectId"
                                :studyClearState="info.studyClearState"
                                :tabs="activeName"
                                ref="activeName_4"
                            />
                        </el-tab-pane>
                        <el-tab-pane label="考试签到照片" name="activeName_5">
                          <studentInfoCertificationRecord_5
                              :userId="UserId || info.userId"
                              :projectId="info.projectId"
                              :studyClearState="info.studyClearState"
                              :tabs="activeName"
                              ref="activeName_5"
                          />
                        </el-tab-pane>
                        <el-tab-pane label="每日签到照片" name="activeName_6">
                          <studentInfoCertificationRecord_6
                              :userId="UserId || info.userId"
                              :projectId="info.projectId"
                              :studyClearState="info.studyClearState"
                              :tabs="activeName"
                              ref="activeName_6"
                          />
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
        <!-- 消息提醒对话框 -->
        <el-dialog
            title="消息提醒"
            :visible.sync="newsDialog"
            width="600px"
            @closed="dialogColsed"
            >
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="提醒模板" prop="docType">
                    <el-radio-group class="remindTemplate" v-model="ruleForm.docType" @change="handleChange">
                        <el-radio label="1">换认证模板</el-radio>
                        <el-radio label="2">作弊违规提醒</el-radio>
                        <el-radio label="3">衣冠不整提醒</el-radio>
                        <el-radio label="4">其他</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="提醒内容" prop="noticeContent" class="noticeContent">
                    <el-input type="textarea" v-model="ruleForm.noticeContent" :rows="5" resize="none" maxlength="70" show-word-limit ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="newsDialog=false">取消</el-button>
                <el-button size="small" class="bgc-bv" @click="submitForm">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import { resetKeepAlive } from "@/utils/common";
    import studentInfoCertificationRecord_1 from "@/views/classListCom/classDetailsFile/studentInfoCertificationRecord_1.vue";
    import studentInfoCertificationRecord_2 from "@/views/classListCom/classDetailsFile/studentInfoCertificationRecord_2.vue";
    import studentInfoCertificationRecord_3 from "@/views/classListCom/classDetailsFile/studentInfoCertificationRecord_3.vue";
    import studentInfoCertificationRecord_4 from "@/views/classListCom/classDetailsFile/studentInfoCertificationRecord_4.vue";
    import studentInfoCertificationRecord_5 from "@/views/classListCom/classDetailsFile/studentInfoCertificationRecord_5.vue";
    import studentInfoCertificationRecord_6 from "@/views/classListCom/classDetailsFile/studentInfoCertificationRecord_6.vue";
    export default {
        name: "operate/MechanismFaceStudentList",
        components: {
            studentInfoCertificationRecord_1,
            studentInfoCertificationRecord_2,
            studentInfoCertificationRecord_3,
            studentInfoCertificationRecord_4,
            studentInfoCertificationRecord_5,
            studentInfoCertificationRecord_6
        },
        data() {
            return {
                // 上一页传过来的数据集合
                info: {},
                //获取当前人的信息
                currentInfo: {},
                // tabs - 默认值
                activeName: "activeName_1",
                // 学员id
                UserId: "",
                // 页面来源 (班级列表进来的还是照片检查进来的inspectionOfCertifiedPhotos)
                pageSource:"",
                // 消息提醒对话框可见性
                newsDialog:false,
                // 消息提醒表单
                ruleForm:{
                    docType:"4",// 提示模板
                    noticeContent:""//提示内容
                },
                // 表单验证规则
                rules:{
                    noticeContent:[{required:true, message:'请输入提醒内容', trigger:"blur"}]
                },
            };
        },
        created() {
            this.info = JSON.parse(this.$route.query.info);
            console.log(this.info.faceType)
            if(['10','20'].includes(this.info.faceType)){ // 未处理
                this.activeName = 'activeName_1';
            } else if (['30','40','45'].includes(this.info.faceType)) { // 考试签到照片
                this.activeName = 'activeName_5';
            } else if (['80'].includes(this.info.faceType)) { // 每日签到照片
                this.activeName = 'activeName_6';
            } else { // 未处理
                this.activeName = 'activeName_1';
            }
            // 判断从哪进来的路由
            if(this.$route.query.pageSource==="inspectionOfCertifiedPhotos"){
                // 认证照片检查进来的
                this.pageSource="inspectionOfCertifiedPhotos";
            }else{
                this.info.projectName = sessionStorage.getItem('projectName');
                this.switch();
            }
        },
        methods: {
            // 消息提醒按钮
            messageReminder(){
                this.newsDialog=true;
            },
            // 消息提醒类型改变
            handleChange(val) {
                const {info,currentInfo,ruleForm}=this;
                if(val === "1") {
                    ruleForm.noticeContent = info.userName || currentInfo.userName + '学员您好，系统检测到您的认证照片存在异常，请在我的－个人信息－人脸认证处检查照片并重新认证。';
                } else if(val === "2") {
                    ruleForm.noticeContent = info.userName || currentInfo.userName + '学员您好，系统检测到您在学习过程中存在违规情况（使用照片认证、使用加速器播放等），请确保为本人学习，以免影响学习结果。';
                } else if(val ==="3") {
                    ruleForm.noticeContent = info.userName || currentInfo.userName +"学员您好，系统检测到您在学习过程中存在躺着学习或衣冠不整的情况，根据省平台最新要求，请确保坐姿端正并保证衣冠整齐，以免影响学习结果。";
                } else{
                    ruleForm.noticeContent = "";
                }
                this.$refs.ruleForm.clearValidate("noticeContent");
            },
            // 消息提醒确认
            submitForm() {
                const {ruleForm,info}=this;
                this.$refs.ruleForm.validate((valid) => {
                    if(valid){
                        this.$post('/biz/userface/inspect/notice',{
                            userId:info.userId,
                            noticeContent:ruleForm.noticeContent
                        }).then(res => {
                            if(res.status == "0") {
                                this.$message.success(res.message);
                                this.newsDialog = false;
                            }
                        });
                    }
                });
            },
            // 对话框关闭
            dialogColsed(){
                this.$refs.ruleForm.resetFields();
            },
            // 恢复/暂停 - 学习
            pauseResume(){
                this.$post('/biz/projectUserClass/pause/switch',{
                    userId:this.info.userId,
                    projectId:this.info.projectId,
                    isPause:this.currentInfo.isPause ? false : true,
                }).then(res => {
                    if(res.status == "0") {
                        this.$message.success(res.message);
                        this.currentInfo.isPause = this.currentInfo.isPause ? false : true;
                    }
                });
            },
            // 点击tabs选项卡
            handleClick(tab) {
                this.activeName = tab.name;
                if (tab.name == "activeName_1") {
                    this.$refs[tab.name].getListData();
                }
                if (tab.name == "activeName_2") {
                    this.$refs[tab.name].getListData();
                }
                if (tab.name == "activeName_3") {
                    this.$refs[tab.name].getData(-1);
                }
                if (tab.name == "activeName_4") {
                    this.$refs[tab.name].getHighQualityPhotoList();
                }
                if (tab.name == "activeName_5") {
                  this.$refs[tab.name].getExamSignPhotoList();
                }
                if (tab.name == "activeName_6") {
                  this.$refs[tab.name].getExamSignPhotoList();
                }
            },
            // 跳转学员信息
            goHerf() {
                this.$router.push({
                    path: "/web/studentInfo",
                    query: {
                        projectId: this.info.projectId,
                        pageNum:this.info.pageNum,
                        refresh: false,
                    },
                });
            },
            switch(UserIndex, UserId,PageNum) {
                let param = {
                    projectId: this.info.projectId, // 班级id
                    idcard: this.info.idcard, // 身份证号
                    userName: this.info.userName, // 学员姓名
                    mobile: this.info.mobile, // 学员电话
                    authentication: this.info.authentication,
                    allowPrint: this.info.allowPrint,
                    userId: UserId || this.info.userId, // 学员id
                    userIndex: UserIndex == 0 ? 0 :UserIndex|| this.info.userIndex,
                    pageNum:PageNum || this.info.pageNum, // 页号
                    pageSize:this.info.pageSize // 页大小
                }
                return this.$post("biz/project/user/faceIdens/switch", param).then(
                    (ret) => {
                        this.info.studyClearState=ret.data.studyClearState;
                        return (this.currentInfo = {
                            ...ret.data,
                        });
                    }
                ).catch(e=>{
                    console.error("获取信息出错",e);
                });
            },
            // 上一个
            async prevClick(activeName, currentInfo) {
                // if (activeName == "activeName_1") {
                //   await this.$refs[activeName].getListData();
                // }
                // if (activeName == "activeName_2") {
                //   await this.$refs[activeName].getListData();
                // }
                // if (activeName == "activeName_3") {
                //   await this.$refs[activeName].getData(-1);
                // }
                this.UserId = currentInfo.lastUserId;
                this.switch(currentInfo.lastUserIndex, currentInfo.lastUserId,currentInfo.lastPageNum);
            },
            // 下一个
            async nextClick(activeName, currentInfo) {
                // if (activeName == "activeName_1") {
                //   await this.$refs[activeName].getListData();
                // }
                // if (activeName == "activeName_2") {
                //   await this.$refs[activeName].getListData();
                // }
                // if (activeName == "activeName_3") {
                //   await this.$refs[activeName].getData(-1);
                // }
                this.UserId = currentInfo.nextUserId;
                this.switch(currentInfo.nextUserIndex, currentInfo.nextUserId,currentInfo.nextPageNum);
            },
        },
        beforeRouteLeave: resetKeepAlive,
    }
</script>
<style lang="less" scoped>
    .faceCheck {
        display: flex;
        justify-content: space-between;
    }
    .remindTemplate{
        .el-radio{
            margin-bottom: 10px;
        }
    }
</style>